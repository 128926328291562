<template>
  <form class="signUp findId findPasswordStep1" id="signupForm" @submit.prevent="onSubmit(userNm, spTelNo)">
    <p class="etext">아이디를 잊으셨나요? 아래의 정보를 입력해주세요</p>
    <fieldset>
      <label class="k-form-field">
        <input  type="text"
                ref="userNmRef"
                class="signUpInput"
                v-model.trim="userNm"
                placeholder="이름"
                autofocus
        />
      </label>
      <label class="k-form-field">
        <input  type="text"
                ref="spTelNoRef"
                class="signUpInput"
                v-model.trim="spTelNo"
                placeholder="휴대전화번호 예)01011112222"
                maxlength="11"
        />
      </label>
      <p class="lnote status">
      </p>
      <input type="submit" value="확인" class="mid_btn orange login_btn" style="cursor: pointer;">
    </fieldset>
    <p class="balink">
      <router-link to="/login">로그인화면으로 돌아가기</router-link>
    </p>
  </form>

</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'FindId',
    props: {
      param: {
        type: Object,
        required: false
      }
    },
    computed: {
      /* ...mapState({
        errors: state => state.loginStore.loginErrors,
      }), */
    },
    methods: {
      validator() {
        const vm = this

        if(!vm.userNm) {
          kendo.alert('이름을 입력해 주세요.').bind('close', function() {
            vm.$refs.userNmRef.focus()
          })
          return false
        }
        if(!vm.spTelNo) {
          kendo.alert('휴대전화번호를 입력해 주세요.').bind('close', function() {
            vm.$refs.spTelNoRef.focus()
          })
          return false
        }

        return true

      },
      onSubmit (userNm, spTelNo) {

        if (this.validator()) {

          // 아이디 찾기
          this.$store.dispatch('FETCH_FIND_ID', { userNm, spTelNo }).then(data => {
            this.$emit('child', data.resultData)
          })
        }
      },
    },
    data () {
      return {
        userNm: null,
        spTelNo: null,
      }
    },
  }

</script>
